<template>

<div>

<div>

  <b-row> 

    <b-col cols="6" md="3" v-for="item in items" :key="item._id">
  <b-card
    :title="item.product_code"
    :img-src="item.item_photo"
    img-alt="Image"
    img-top
    img-height="100%"
    img-width="100%"
    tag="article"
    class="mb-2 text-center position-relative item-card"
  >
    <b-col cols="12" md="12">
    <label class="item_name_text">{{item.item_name}}</label>
    </b-col>
      <b-col cols="12" md="12">
          <b-form-group label="Qty">
              <b-form-select v-model="item.selected_quantity" v-if="item.custom != 'yes'">
                     <b-form-select-option :value="i" v-for="i in quantity" :key="i._id">{{i}}</b-form-select-option>
              </b-form-select>
              <b-form-select v-model="item.selected_quantity" v-if="item.custom == 'yes'">
                     <b-form-select-option :value="i" v-for="i in item.custom_quantity" :key="i._id">{{i}}</b-form-select-option>
              </b-form-select>
          </b-form-group>
    </b-col>
    <b-col cols="12" md="12" v-if="item.selected_quantity != null">
  <b-button variant="danger" @click="item.selected_quantity=null">
      <span>X</span>
  </b-button>
    </b-col>
  </b-card>

    </b-col>

  </b-row>

          <!-- Confirm Order Modal -->
        <b-modal 
            id="confirm-order" centered ref="confirm-order" ok-only
            ok-variant="success" header-bg-variant="warning" footer-class="justify-content-center"
            ok-title="Submit" 
            @ok="submitOrder"
            @hidden="resetData"
            :title="title"
            no-close-on-backdrop
            size="lg"
        >
            <b-alert variant="danger" show>
                <div class="alert-body"> {{error_message}} </div>
            </b-alert>
            <b-form>
              <b-row>
              <b-col cols="12" md="12" class="text-center d-flex flex-column">
                <label class="delivery_header_text">Select Delivery Timeslot</label>
              </b-col>
              <b-col cols="12" md="12">
                  <b-form-select v-model="selected_timeslot">
                     <b-form-select-option :value="i" v-for="i in timeslot" :key="i._id">{{i}}</b-form-select-option>
                  </b-form-select>
              </b-col>
              </b-row>
              <br>
              <b-row>
              <b-col cols="8" md="8">
                <label class="confirm_item_header_text">Item(s)</label>
              </b-col>
              <b-col cols="4" md="4" style="text-align:right">
                <label class="confirm_item_header_text">Qty</label>
              </b-col>
              </b-row>
              <hr>
              <b-row v-for="item in selectedItem" :key="item.id">
              <b-col cols="10" md="10">
                <label class="confirm_item_text">{{item.item_name}}</label>
              </b-col>
              <b-col cols="2" md="2" style="text-align:right">
                <label class="confirm_item_text"> {{item.selected_quantity}}</label>
              </b-col>
              </b-row>  
            </b-form>

        </b-modal>
  <b-row>
  <b-col cols="12" md="12" class="text-center d-flex flex-column">
  <b-button variant="warning" @click="openModal" v-if="items.length != 0">
      <span>Submit Order</span>
  </b-button>
  </b-col>
  </b-row>

  <hr>
  
</div>        
    </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert, BFormFile, BImg
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Bus from "../../../event-bus";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from "moment-timezone";
import axios from 'axios';

export default {
    mixins: [togglePasswordVisibility],
    computed: {
    },
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption,BModal,BBreadcrumb, BFormGroup, BForm, VueGoogleAutocomplete, BInputGroupPrepend, BInputGroup, BInputGroupAppend, BAlert, BFormFile, moment, BImg,
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {

    return {
            //selected_quantity:[],
            quantity:[],
            custom_quantity:[],
            items:[],
            filter_item: [],
            selectedItem: [],
            selected_timeslot: [],
            timeslot: [],
            delivery_date: '',
            title: null,
            error_message: null, 
            currentTime: moment().tz('Asia/Singapore').format('HH:mm')
        }
    },

    methods : {

      getItemsData(){

      return this.$store.dispatch(POST_API, {
           data:{
            id: this.$store.getters.currentUser._id
           },
           api: '/api/get-user-items-all'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.items = data;

                data.forEach(i => {
                  this.filter_item.push(i.item_name)
                })

                return this.items;
            }
        });
    },

      getItemsDropdownData(){

      return this.$store.dispatch(POST_API, {
           data:{
            site: this.$store.getters.currentUser.site
           },
           api: '/api/get-items-dropdown'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.quantity = data[0].quantity[0];

               // data.forEach(i => {
               //   this.filter_item.push(i.item_name)
                //})

                return this.quantity;
            }
        });
    },   
    getTimeslotData(){

      return this.$store.dispatch(POST_API, {
           data:{
            site: this.$store.getters.currentUser.site
           },
           api: '/api/get-timeslot'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                data.forEach(i => {
                if ((i.name == 'Morning' && this.currentTime > '11:00'))
                {
                  this.delivery_date = 'Tomorrow'
                }
                else if (i.name == 'Afternoon' && this.currentTime > '17:00')
                {
                  this.delivery_date = 'Tomorrow'
                }
                else if (i.name == 'Night' && this.currentTime > '22:00')
                {
                  this.delivery_date = 'Tomorrow'
                }
                else {
                  this.delivery_date = 'Today'
                }
                  this.timeslot.push(this.delivery_date + ',' + i.name )
                })

                this.timeslot.sort(function (a, b){ // Sort by Today first then Tomorrow
                  if (b > a) return -1;
                })

                return this.timeslot;
            }
        });
    },   
    submitOrder() {

      return this.$store.dispatch(POST_API, {
           data:{
            id: this.$store.getters.currentUser._id,
            site: this.$store.getters.currentUser.site,
            created_by: this.$store.getters.currentUser.name,
            unit_number: this.$store.getters.currentUser.unit_number,
            created_date: moment().tz('Asia/Singapore').format('YYYY-MM-DD HH:mm'),
            completion_date: '',
            delivery_timeslot: this.selected_timeslot,
            items: this.selectedItem,
            order_status: 'New'            
           },
           api: '/api/submit-order'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.errorAlertCF();
            } else {
              this.successAlert().then((result) => {

                this.refreshPage();

              });
            }
        });
    },
    openModal() {
      var checkItemsQuantity = 0;
      for (var i =0; i < this.items.length; i++)
      {
        if (this.items[i].selected_quantity)
        {
          checkItemsQuantity = checkItemsQuantity + 1;
          var obj = {_id: this.items[i]._id, item_name: this.items[i].item_name,
          selected_quantity: this.items[i].selected_quantity,
          product_code: this.items[i].product_code}
          this.selectedItem.push(obj)
        }
      }
      if (checkItemsQuantity == 0)
      {
			return Swal.fire({
				position : 'center',
				icon     : 'error',
				title    : 'No Quantity selected'
			});
      }
      else {
      this.title = "Order Confirmation"
      this.$refs['confirm-order'].show();
      }
    },
    resetData() {
      this.selectedItem = [];
      this.selected_timeslot = [];
    },
    refreshPage() {
      setTimeout(() => {
      document.location.reload()}, 1500);
    },
    
    },
    async mounted(){
        await this.getItemsData();
        this.getItemsDropdownData();
        this.getTimeslotData();
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
    @media (min-width :768px)
    {
    .item-card .card-title {
      font-size: 20px;
    }
    .item-card .card-img-top {
      width: 100%;
      height: 20vw;
      object-fit: fill;
    }
    .item_name_text {
      font-size: 16px;
    }
    }
    @media (max-width: 767px)
    {
    .item-card .card-title {
      font-size: 20px;
    }
    .item-card .card-img-top {
      width: 100%;
      height: 40vw;
      object-fit: fill;
    }
    .item_name_text {
      font-size: 14px;
    }
    }
    .confirm_item_header_text {
      font-size: 20px;
      font-weight: bold;
    }
    .confirm_item_text {
      font-size: 16px;
    }
    .delivery_header_text {
      font-size: 20px; 
      font-weight: bold;
    }
</style>

<style lang="scss">
    .pac-container {
  z-index: 99999 !important; /* needs to be > z-index modal */
    }
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
